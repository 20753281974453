<template>
  <div class="adopted"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.adopted {
  width: 100%;
  height: 100%;
 
  background-size: cover;
}
</style>